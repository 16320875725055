<template>
  <div class="user-management">
    <el-button type="primary" @click="openAddUserDialog" style="margin-bottom:15px">新增</el-button>

    <!-- 新增/编辑表单对话框 -->
    <el-dialog :title="isEdit ? '编辑用户' : '新增用户'" :visible.sync="addUserDialogVisible" width="500px" center>
      <el-form :model="addFormData" ref="addUserForm" :rules="rules" label-width="50px" v-loading="addFormLoading">
        <el-form-item label="账号" prop="userName">
          <el-input v-model="addFormData.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="addFormData.gender">
            <el-option label="男" value="B"></el-option>
            <el-option label="女" value="G"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="telphone">
          <el-input v-model="addFormData.telphone"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input :type="passwordVisible ? 'text' : 'password'" v-model="addFormData.password">
            <template v-if="!isEdit" slot="append">
              <el-button @click="passwordVisible=!passwordVisible" icon="el-icon-view"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select v-model="addFormData.role">
            <el-option label="用户" value="2"></el-option>
            <el-option label="管理员" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addUserDialogVisible = false" :disabled="addFormLoading">取消</el-button>
        <el-button type="primary" @click="submitAddUserForm" :disabled="addFormLoading">确定</el-button>
      </div>
    </el-dialog>

    <!-- 用户列表表格 -->
    <el-table stripe v-loading="dataListLoading" :data="userList" border style="width: 100%" :header-cell-style="{ textAlign: 'center', verticalAlign: 'middle' }" :cell-style="{ textAlign: 'center', verticalAlign: 'middle' }">
      <el-table-column type="index" label="序号" width="50" :resizable="false"></el-table-column>
      <el-table-column prop="userName" label="账号" width="200" :resizable="false"></el-table-column>
      <el-table-column prop="name" label="姓名" width="150" :resizable="false"></el-table-column>
      <el-table-column prop="gender" label="性别" width="50" :resizable="false"></el-table-column>
      <el-table-column prop="telphone" label="电话号码" width="200" :resizable="false"></el-table-column>
      <el-table-column prop="role" label="角色" width="100" :resizable="false"></el-table-column>
      <el-table-column prop="createName" label="创建人姓名" width="150" :resizable="false"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" :resizable="false"></el-table-column>
      <el-table-column label="操作" width="150" :resizable="false">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editUser(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="deleteUser(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页查询 -->
    <div class="pagination-container">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="[10, 20, 30, 40]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserList, saveUser, deleteUser, getUserInfo } from '@/api/user'
export default {
  data() {
    return {
      current: 1,
      size: 10,
      total: 20,
      userList: [],
      dataListLoading: false,//用户列表加载状态
      addUserDialogVisible: false,//新增用户对话框是否可见
      passwordVisible: false,//密码是否可见
      addFormLoading: false,//新增表单加载状态
      isEdit: false, // 是否为编辑模式
      addFormData: {
        id: '', // 编辑时需要的参数
        userId: '', // 编辑时需要的参数
        userName: '',//账号
        gender: 'B',//性别(男: B  女: G)
        name: '',//名字
        telphone: '',//电话
        password: '',//密码
        role: '2'//角色(1:管理员 2:用户)
      },
      // 表单验证规则
      rules: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '请输入有效的账号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        telphone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '请输入有效的电话号码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    // 获取用户列表
    getUserList() {
      this.dataListLoading = true
      getUserList({ current: this.current, size: this.size }).then(res => {
        res.data.records.forEach(user => {
          user.gender = user.gender === 'N' ? '未知' : user.gender === 'B' ? '男' : '女';
          user.role = user.role === '0' ? '超级管理员' : user.role === '1' ? '管理员' : '用户';
        });
        this.userList = res.data.records
        this.total = res.data.total
        this.dataListLoading = false
      })
    },
    // 打开新增用户对话框
    openAddUserDialog() {
      this.isEdit = false;
      this.addFormData = {
        id: '',
        userId: '',
        userName: '',
        gender: 'B',
        name: '',
        telphone: '',
        password: '',
        role: '2'
      };
      this.addUserDialogVisible = true;
    },
    // 提交新增/编辑用户表单
    submitAddUserForm() {
      this.$refs.addUserForm.validate((valid) => {
        if (valid) {
          this.addFormLoading = true;
          saveUser(this.addFormData).then(res => {
            this.$message({
              type: 'success',
              message: this.isEdit ? '编辑用户成功' : '新增用户成功'
            });
            // 清空表单
            this.addFormData = {
              id: '',
              userId: '',
              userName: '',
              gender: 'B',
              name: '',
              telphone: '',
              password: '',
              role: '2'
            }
            this.addUserDialogVisible = false;
            this.addFormLoading = false;
            this.getUserList()
          }).catch((value) => {
            this.$message({
              type: 'error',
              message: value.msg
            });
            this.addFormLoading = false;
          })
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
    // 编辑用户
    editUser(row) {
      this.isEdit = true;
      getUserInfo({ id: row.id }).then(res => {
        this.addFormData = res.data;
        // this.addFormData.password = '';
        this.addUserDialogVisible = true;
      })
    },
    // 删除用户
    deleteUser(row) {
      this.$confirm('是否删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUser({ id: row.id }).then(res => {
          this.$message({
            type: 'success',
            message: '删除用户成功'
          });
          this.getUserList();
        }).catch((value) => {
          this.$message({
            type: 'error',
            message: value.msg
          });
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 分页查询
    handleSizeChange(val) {
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getUserList()
    }
  }
}
</script>

<style scoped>
.pagination-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>